/**
 * @fileOverview
 * todo 这文件干嘛使的
 * @author xiaof
 * @since 2021/2/8.
 */

'use strict'
// import store from '../store/index'
// import { message } from 'ant-design-vue'
// 接口调用后的处理
export const isSuccess = (res, onSuccess, onError, options) => {
  if (res.data.code !== '200') {
    if (res && (res.data.msg !== '' || res.data.msg !== null)) {
      this.$message.error(res.data.msg)
    } else {
      onError()
    }
  } else {
    onSuccess()
  }
}

// 单一menuList
export function getMenuList(menuList) {
  return menuList.filter(item => {
    if ((item.children && item.children.length === 1 && item.children[0].name === 'home')) {
      return item
    }
  })
}

// 从menuList中提取subList
export function getSubList(menuList) {
  return menuList.filter(item => {
    if (item.children && item.children.length && item.children[0].name !== 'home') {
      return item
    }
  })
}

// 获取cookie
export function getCookie(name) {
  let arr
  let reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)')
  arr = document.cookie.match(reg)
  if (arr) {
    return unescape(arr[2])
  } else {
    return null
  }
}

// 运行一段代码，没啥用写的好看一些
export function run(func) {
  func()
}

// 分页展示问题严重
export function pagerules(dataLength, deleteLength, page) {
  let pageNum = page
  if (dataLength === deleteLength) {
    if (page !== 1) {
      pageNum = page - 1
    }
  }
  return pageNum
}

// 导出excel表格，对返回数据的处理
export const dealExcelRespose = (res, filename) => {
  const url = window.URL.createObjectURL(new Blob([res]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
}
